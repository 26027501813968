@mixin tableHeadline () {
    display: grid;
    justify-content: center;
    border-bottom: 1px solid lightgray;
    padding: 10px 5px;

    & span {
        font-weight: 700;
        text-align: center;
        text-transform: capitalize;
    }
}
    
@mixin tableBody () {
    display: grid;
    justify-content: center;
    align-items: center;
    padding: 5px;
}