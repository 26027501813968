.latest-posts-slider {
  width: 100%;

  .swiper-pagination-bullets {
    bottom: 0;

    .swiper-pagination-bullet-active {
      background: #eb6438;
    }
  }

  .slide-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px 30px 65px;

    &__image {
      max-width: 50%;
      flex: 1 1 50%;
      display: flex;
      justify-content: center;

      img {
        width: auto !important;
        height: auto !important;
        max-width: 100% !important;
        object-fit: cover;
        border-radius: 15px;
      }

      @include media-breakpoint-down(sm) {
        max-width: 100%;
        margin-bottom: 20px;
      }
    }

    &__content {
      max-width: 50%;
      flex: 1 1 50%;
      padding-left: 30px;

      h5 {
        color: #dc592a;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 30px;
      }

      p {
        font-size: 17px;
        margin-bottom: 20px;
      }

      span {

        b {
          display: block;
          margin-bottom: 5px;
        }
      }

      .actions {
        display: block;
        margin: 20px 0;
        text-align: left;

        @include media-breakpoint-down(sm) {
          margin-bottom: 0;
        }
      }

      @include media-breakpoint-down(sm) {
        max-width: 100%;
        text-align: left;
        padding-left: 0;
      }
    }

    @include media-breakpoint-down(sm) {
      display: block;
    }
  }
}