.cv-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-items: flex-start;

    & > * {
        flex: 1 1 30%;
        max-width: 30%;
        width: 100%;
        margin-left: 0 !important;
        margin-right: 0 !important;

        @include media-breakpoint-down(xl) {
            max-width: 100% !important;
        }
    }

    & > .btn {
        @include media-breakpoint-down(xl) {
            margin-bottom: 30px !important;
        }
    }

    .form-group.row > * {
        max-width: 100% !important;
        flex: 1 1 100% !important;
        padding: 0 !important;
    }

    @include media-breakpoint-down(xl) {
        display: block !important;
    }
}
