// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}

// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// CUSTOM SCSS
@import "./scss/mixins";
@import "./scss/local.vars";

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

/* Override Material UI classes ~~ START! */

.MuiInput-underline:focus:not(.Mui-focused):before, .MuiInput-underline:active:not(.Mui-focused):before, .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid $primary !important;
}

.MuiFormLabel-root.Mui-focused {
  color: $primary !important;
}

.MuiInput-underline:after {
  border-color: $primary !important;
}

/* Override Material UI classes ~~ END! */

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;

  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none $whiteColor !important;
}

.example-code > .json {
  background: none $whiteColor !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}

/* MY PROFILE NATEK CUSTOM STYLES ~~ START! 

  Note:
    1. It's not a good practice to overuse "important", but in these cases, this approach is good to override previous default Metronic styles!
*/

.metronic_route-container {
  padding: 0px !important;
}

.metronic_wrapper {
  padding-left: 0px !important;
  background-color: $bckColor;
}

.metronic_header {
  left: 0 !important;
}

.metronic_settings_item {
  display: flex;
  align-items: center;
  color: $silverColor;
}

.metronic_settings_item:hover i {
  color: $blueColor;
}

.metronic_natek-logo {
  width: 140px;
}

.metronic_custom-dialog {
  background-color: rgba(0, 0, 0, .7);
}

.metronic_privacy-btn span:nth-child(1) {
  color: $primary;
}

.metronic_user-img {
  max-width: 70px !important;
  height: 70px !important;
  transform: translateZ(0);
}

.metronic_user-information {
  width: 75%;
}

.metronic_privacy-container {
  margin: 0 20px
}
.privacy_list {
  color: $orangeColor;
  font-size: 1.3rem;
}
.privacy_list_ul {
  list-style: none;
}
.privacy_list_ul li::before {
  content: '\2022';
  color: $orangeColor;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.metronic_privacy-text {
  margin: 0 auto 10px;
  color: $orangeColor;
  font-size: 1.7rem;
  padding: 5px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  width: max-content;
}

.metronic_social-icon {
  margin: 0 .5rem;
}

.metronic_follow-us {
  margin-top: 1rem;
  color: $orangeColor;
  font-size: 1.3rem;
}

.metronic_social-news-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.metronic_container {
  flex: 1;
  margin: 5px;
  // height: max-content;
  // align-self: center;
}

.column-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.job-select-dropdowns {
  flex-direction: row;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
  }

  & > * {
    @include media-breakpoint-down(lg) {
      width: 100%;
      max-width: 100%;
      margin-bottom: 15px;
    }
  }
}

.button-container {
  width: 612px;
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-bottom: 1px solid #EBEDF3;
  padding: 1.5rem 2.25rem;
}

.metronic_social-news-container {
  margin-bottom: 1rem;
}

.makeStyles-search-134 {
  margin-left: unset !important;
}

.metronic_fixed-table {
  height: 400px !important;
  overflow-y: auto;
}

.metronic_not-fixed-table {
  height: inherit;
  overflow-y: hidden;
}

.metronic_sticky-container {
  width: inherit;
  position: sticky;
  top: 0;
  padding: 0 2rem;
  z-index: 2
 }
 
.metronic_recent-open-positions-headline {
  @include tableHeadline();
}

.metronic_recent-job-applications-headline {
  @include tableHeadline();
  grid-template-columns: repeat(7, 1fr);
}

.metronic_recent-job-applications-headline-home {
  @include tableHeadline();
  grid-template-columns: repeat(6, 1fr);
}

.metronic_jobs-headline {
  @include tableHeadline();
  background-color: $whiteColor;
 }

.metronic_recent-open-positions-body {
  @include tableBody();
}

.metronic_job-applications-body {
  @include tableBody();
  grid-template-columns: repeat(7, 1fr);
  margin: 5px 0px;
}

.metronic_job-applications-body-home {
  @include tableBody();
  grid-template-columns: repeat(6, 1fr);
  margin: 5px 0px;
}

.metronic_jobs-body {
  @include tableBody();
}

.metronic_table-body-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .metronic_jobs-body + .metronic_jobs-body,
  .metronic_job-applications-body + .metronic_job-applications-body,
  .metronic_job-applications-body-home + .metronic_job-applications-body-home,
  .metronic_recent-open-positions-body + .metronic_recent-open-positions-body {
    border-top: 1px solid #d3d3d3;
  }

  & span {
    text-align: center;
  }
}

.metronic_table-body-below-dimension {
  margin-top: 2.5rem;

  & p span:nth-child(1),
  & div span:nth-child(1)  {
    font-weight: 700;
  }

  & p,
  & div {
    border-bottom: 1px solid $borderColor;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }

  & button {
    width: 70px;
  }
}

.metronic_profile-card-containe {
  width: 250px !important;
}

.metronic_settings-icon:hover,
.metronic_email-icon,
.metronic_privacy-btn {
  color: $orangeColor !important;
}

.fa-plus-circle {
  color: $orangeColor!important;
}

.fa-info-circle {
  color: $lightGrayColor!important;
}

.fa-sync-alt {
  color: darkgray !important;
}

.btn-light-primary:hover i { 
  color: $whiteColor !important;
}

.metronic_signature_container {
  width: 40vw;
  margin: 0 auto;
}

.metronic_dialog-title {
  background-color: $orangeColor;
  padding: 5px 10px!important;

  & * {
    color: $whiteColor;
  }
}

.metronic_change-password {
  margin: 2rem auto;
}

.metronic_nate-logo-puzzle {
  width: 20px;
  height: 20px
}

.metronic_hyperlink {
  font-size: 11px !important;
}

.metronic_details-container {
  background-color: $whiteSmokeColor;
}

.metronic_symbol-container span {
  background-color: $orangeColor !important;
  color: $whiteSmokeColor!important;

}

.metronic_jobs {
  display: flex;
  justify-content: space-between;
  align-self: center;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.metronic_privacy-hyperlink {
  text-decoration: underline;
}

.metronic_privacy-policy-hyperlink { 
  text-decoration: underline; 
}

.metronic_fullname,
.metronic_email,
.metronic_occupation {
  color: $lightGrayColor !important;
}

.metronic_auth-title,
.metronic_list-item,
.metronic_auth-copy {
  color: black !important;
}


.metronic_capitalize_text {
  text-transform: capitalize;
}

.required-identification {
  color: red;
}

.global-warning {
  display: flex;
  margin-top: 12px;
  margin-right: 12px;
  font-size: 1.15em;
  color: #F64E60;
}

.error-text {
  color: #F64E60;
}

.display-end {
  justify-content: flex-end;
}

.registration-cv-chip {
  margin: 0.5em 0;
}

.below-dimension-last-child {
  border-bottom: 3px solid silver !important;
  padding-bottom: 1.8rem !important;
}

.justified-text {
  text-align: justify;
}

.no-wrap-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-select-input {
  border: none;
  background-color: $gray-100 !important;
}

.gray-background {
  background-color: $gray-100 !important;
}

.profile-icon {
  width: 50px
}

.linked-in-url {
  width: 500;
  text-align: right;
}

/* MEDIA QUERIES ~~ START! */

@media(max-width: 576px) {
  .metronic_signature_container {
    width: 90vw;
  }

  .metronic_login-logo {
    width: 100%
  }

  .metronic_user-information {
    width: 65%;
  }

  .metronic_footer-nav {
    justify-content: center;

    & a {
      padding: 5px 8px !important;
    }
  }

  .metronic_privacy-text {
    margin-top: 20px;
    width: 320px;
    text-align: center;
  }

  .metronic_change-password {
    width: 90vw;
  }

  .metronic_footer-nav {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
 }
}

@media(max-width: 992px) {
  .metronic_jobs {
    height: inherit;
    flex-wrap: nowrap !important;
    padding: 10px 0px 0px !important;

    & h3 {
      background-color: $whiteColor;
      width: calc(100%);
      margin: 0 0 10px 0 !important;
      padding-top: 12px;
      padding-bottom: 6px;
    }
  }

  .topbar {
    display: none !important;
  }
  
  .metronic_signature_container {
    margin-top: 4rem;
  }

  .metronic_homepage-container,
  .container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .metronic_footer-container {
    flex-direction: column !important;
  }

}

@media (min-width: 576px) {
  .metronic_change-password {
    width: 70vw;
  }

  // .metronic_homepage-left-container {
  //   flex: 0 1 45%;
  // }

  // .metronic_homepage-right-container {
  //   flex: 0 1 55%;
  // }
}

@media (min-width: 992px) {
  
  .header-fixed.subheader-fixed.subheader-enabled .wrapper {
    padding-top: 85px;
  }

  .topbar {
    min-width: 150px;
  }

  .metronic_login-left-side {
    max-width: 500px !important;
  }

  .metronic_auth-footer {
    align-items: center;
    flex-direction: column !important;
  }

  .metronic_header-menu-wrapper {
    width: 88% !important;
  }

  .metronic_header-menu-wrapper .menu-nav {
    width: 100%;
  }

  .metronic_last-item {
    margin-left: auto;
  }

  .metronic_my-profile-container {
    max-width: 750px;
    width: 750px;
  }
}

@media (min-width: 1200px) {
  .metronic_header-menu-wrapper {
    width: 92% !important;
  }

  // .metronic_homepage-left-container {
  //   flex: 0 1 30%;
  // }

  // .metronic_homepage-right-container {
  //   flex: 0 1 70%;
  // }

  .metronic_homepage-container  {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: minmax(100px, 1fr);
    grid-template-areas:
      "userProfile userProfile news news news news"
      "jobApps jobApps jobApps jobs jobs jobs";
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .metronic_user-profile {
    grid-area: userProfile;
  }

  .metronic_news {
    grid-area: news;
  }

  .metronic_job-apps {
    grid-area: jobApps;
  }

  .metronic_latest-jobs {
    grid-area: jobs;
  }
}

@media(max-width: 300px) {
  .metronic_privacy-text {
    width: 220px;
  }
}


